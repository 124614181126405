<template>
  <v-row>
    <v-col cols="12" class="px-0 pb-0">
      <v-toolbar color="transparent" flat height="55" dense>
        <v-toolbar-title class="pa-0 ml-n1 grey-500--text subtitle-1 font-weight-medium">Colección de métricas</v-toolbar-title>
      </v-toolbar>
    </v-col>

    <template v-if="(dashboardSelected && Object.keys(dashboardSelected).length !== 0)">
      <v-col cols="12" class="py-0">
        <v-btn @click="dialogDashboard=true" color="blue-500" text :ripple="false">{{dashboardSelected.name}}</v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <template v-if="metricsAdded.length">
          <v-chip v-for="(metric, i) in metricsAdded" :key="i" color="grey-500" class="mr-2 my-1" small close @click:close="deleteChip(metric.id)">
            <span class="d-inline-block">
              <v-icon v-if="metric.type.name === 'kpi'" small>mdi-counter</v-icon>
              <v-icon v-if="metric.type.name === 'graph'" small>mdi-chart-line-variant</v-icon>
              <v-icon v-if="metric.type.name === 'table'" small>mdi-table-of-contents</v-icon>
              {{$t(`metrics.${metric.name}`)}}
            </span>
          </v-chip>
        </template>
        <template v-else>
          <span class="d-block py-1 body-2 font-italic">Navega por la colección y selecciona métricas para agregarlas al dashboard...</span>
        </template>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-btn @click="goToDashboard(dashboardSelected.id)" :disabled="!metricsAdded.length" color="blue-500">Terminar</v-btn>
      </v-col>
    </template>
    <v-col cols="12" v-else>
      <v-btn @click="dialogDashboard=true" color="blue-500">Seleccionar un dashboard</v-btn>
    </v-col>

    <v-col cols="5" md="4" lg="3" class="pt-4">
      <v-text-field class="ma-0" v-model="search" outlined required single-line dense placeholder="Buscar métrica..." autocomplete="off" hide-details>
        <template v-slot:prepend-inner>
          <v-row class="text-center ml-2 mr-1" justify="center" align="center">
            <v-icon class="icon-autocomplete" color="grey-500" size="20">mdi-magnify</v-icon>
          </v-row>
        </template>
      </v-text-field>
    </v-col>

    <!-- collection metrics -->
    <v-col cols="12">
      <v-row aling="center" justify="start">
        <v-col class="text-center py-10" v-if="!getCollections.length">
          <v-icon color="grey-500" size="70">mdi-chart-line-variant</v-icon>
          <span class="d-block subtitle-1">No existen métricas para tu búsqueda</span>
        </v-col>
        <template v-else>
          <v-col cols="4" v-for="metric in getCollections" :key="metric.id">
            <v-hover v-slot:default="{ hover }" open-delay="200">
              <v-card class="card-apps" hover @click="setActiveMetric(metric)" height="155" border :ripple="false" flat outlined rounded>
                <v-card-text class="py-1">
                  <v-list three-line>
                    <v-list-item class="px-1" :ripple="false" color="blue-500">
                      <v-list-item-content style="min-height: 100px">
                        <v-list-item-title class="pb-1 body-2 grey-500--text font-weight-medium">{{$t(`metrics.${metric.name}`)}}</v-list-item-title>
                        <v-list-item-subtitle class="text--'blue-500" v-html="metric.description" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions class="py-1">
                  <v-spacer />
                  <v-fade-transition v-if="hover">
                    <v-btn icon color="blue-500"><v-icon size="24">mdi-chart-box-plus-outline</v-icon></v-btn>
                  </v-fade-transition>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <!-- end collection metrics -->

    <!-- modal select dashboard -->
    <v-dialog v-model="dialogDashboard" width="600" persistent no-click-animation overlay-color="grey-500">
      <v-card flat outlined rounded>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Seleccionar un dashboard</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-row align="center" justify="center" class="pr-1">
              <v-btn icon retain-focus-on-click :ripple="false" small @click="dialogDashboard=false"><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider/>
        <v-card-text class="pa-0">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" class="text-left">
              <v-col class="py-2">
                <v-text-field v-model="dashboardQuery" outlined required single-line dense placeholder="Escribe para buscar un dashboard..." hide-details/>
              </v-col>
              <v-divider/>
              <v-skeleton-loader v-if="$store.state.dashboards.retrieveLoader" transition="fade-transition" type="list-item-three-line" />
              <template v-else>
                <template v-if="(getDashboards || []).length">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="d in getDashboards" :key="d.id" @click="setDashboard(d.id)" style="cursor: pointer">
                          <td>
                            <span class="body-1 grey-500--text font-weight-medium">{{d.name}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <v-col v-else class="text-center py-10">
                  <v-icon color="grey-500" size="70">mdi-close-circle</v-icon>
                  <span class="d-block subtitle-1">{{ !dashboardQuery ? 'Aún no ha creado dashboards' : 'No se han encontrado resultados'}}</span>
                </v-col>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end modal select dashboard -->

    <!-- add new metrics dialog -->
    <v-dialog v-model="dialogMetric" width="800" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card flat outlined rounded>
        <v-toolbar flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Agregar métrica</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn @click="dialogMetric=false; isActive = false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-title class="pa-0">
          <v-list-item class="py-0">
            <v-list-item-content v-if="Object.keys(activeMetric).length !== 0">
              <v-list-item-title class="body-1 grey-500--text font-weight-medium">{{ $t(`metrics.${(activeMetric || {}).name}`) }}</v-list-item-title>
              <v-list-item-subtitle class="text--'blue-500 font-weight-regular">{{activeMetric.description}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">

          <!-- kpi -->
          <v-card outlined flat v-if="((activeMetric || {}).type || {}).kpi">
            <v-card-text class="pa-3">
              <span class="d-block mb-3 body-2 grey-500--text font-weight-medium">KPI</span>

              <v-sheet outlined rounded width="250" height="140">
                <metric-kpi :metric="activeMetric.name" :interval="activeKpiInterval" :comparative="activeKpiComparative" />
              </v-sheet>

            </v-card-text>
            <v-card-actions class="pa-3 pt-0">
              <template v-if="activeMetric.type.kpi.displayComparative">
                <v-menu right>
                  <template v-slot:activator="{on}">
                    <v-btn class="mr-2" v-on="on" outlined color="grey-500" :disabled="getPeriods.length === 1">{{$t(`api.${activeKpiInterval}`)}}<v-icon right>mdi-chevron-down</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="d in getPeriods" :key="d.value" @click="activeKpiInterval = d">
                      <v-list-item-title>{{$t(`api.${d}`)}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu right>
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" outlined color="grey-500">{{$t(`api.${activeKpiComparative}`)}}<v-icon right>mdi-chevron-down</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="d in getComparatives" :key="d" @click="activeKpiComparative = d; isActive = true">
                      <v-list-item-title>{{$t(`api.${d}`)}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <v-spacer />
              <v-tooltip color="white-0" left max-width="280px" v-if="dashboardSelected && Object.keys(dashboardSelected).length === 0">
                <template v-slot:activator="{on}">
                  <div class="box-hidden" v-on="on"></div>
                </template>
                <span class="d-block px-3 py-2">Selecciona un dashboard para añadir esta métrica.</span>
              </v-tooltip>
              <v-btn @click.native="addMetrics(activeMetric.type.kpi, type = 'kpi')" color="blue-500" :disabled="dashboardSelected && Object.keys(dashboardSelected).length === 0">Agregar</v-btn>
            </v-card-actions>
          </v-card>
          <!-- end kpi -->

          <!--  graph -->
          <v-card class="mt-5" v-if="((activeMetric || {}).type || {}).graph" outlined>
            <v-card-text class="pa-3">
              <span class="d-block mb-3 body-2 grey-500--text font-weight-medium">Gráfico</span>

              <v-sheet outlined rounded>
                <metric-graph :metric="activeMetric.name" :interval="activeGraphInterval" />
              </v-sheet>

            </v-card-text>
            <v-card-actions class="pa-3 pt-0">
              <v-menu right>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" outlined color="grey-500" :disabled="getPeriods.length === 1">{{$t(`api.${activeGraphInterval}`)}}<v-icon right>mdi-chevron-down</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="d in getPeriods" :key="d.value" @click="activeGraphInterval = d">
                    <v-list-item-title>{{$t(`api.${d}`)}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer />
              <v-tooltip color="white-0" left max-width="280px" v-if="dashboardSelected && Object.keys(dashboardSelected).length === 0">
                <template v-slot:activator="{on}">
                  <div class="box-hidden" v-on="on"></div>
                </template>
                <span class="d-block px-3 py-2">Selecciona un dashboard para añadir esta métrica.</span>
              </v-tooltip>
              <v-btn @click.native="addMetrics(activeMetric.type.graph, type = 'graph')"  color="blue-500" :disabled="dashboardSelected && Object.keys(dashboardSelected).length === 0">Agregar</v-btn>
            </v-card-actions>
          </v-card>
          <!-- end graph -->

          <!-- table -->
          <v-card :class="((activeMetric || {}).type || {}).kpi || ((activeMetric || {}).type || {}).graph ? 'mt-5' : ''" outlined flat v-if="((activeMetric || {}).type || {}).table">
            <v-card-text class="pa-3">
              <span class="d-block mb-3 body-2 grey-500--text font-weight-medium">Tabla</span>

              <v-sheet outlined rounded>
                <metric-table :metric="activeMetric.name" />
              </v-sheet>

            </v-card-text>
            <v-card-actions class="pa-3 pt-0">
              <v-spacer />
              <v-tooltip color="white-0" left max-width="280px" v-if="dashboardSelected && Object.keys(dashboardSelected).length === 0">
                <template v-slot:activator="{on}">
                  <div class="box-hidden" v-on="on"></div>
                </template>
                <span class="d-block px-3 py-2">Selecciona un dashboard para añadir esta métrica.</span>
              </v-tooltip>
              <v-btn @click.native="addMetrics(activeMetric.type.table, type = 'table')"  color="blue-500" :disabled="dashboardSelected && Object.keys(dashboardSelected).length === 0">Agregar</v-btn>
            </v-card-actions>
          </v-card>
          <!-- end table -->

        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end add new metrics dialog -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import metrics from '@/collections/metrics'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import { debounce, cloneDeep } from 'lodash'

import MetricGraph from '@/modules/reports/components/MetricGraph'
import MetricKpi from '@/modules/reports/components/MetricKpi'
import MetricTable from '@/modules/reports/components/MetricTable'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    MetricGraph,
    MetricKpi,
    MetricTable
  },
  data: () => ({
    activeGraphInterval: 'monthly',
    activeKpiComparative: 'this_v_last',
    activeKpiInterval: 'monthly',
    activeMetric: {},
    dashboardSelected: {},
    dialogDashboard: false,
    dialogMetric: false,
    draftDashboards: {},
    isActive: false,
    metrics: metrics,
    metricsAdded: [],
    search: '',
    dashboardQuery: '',
    type: '',
    table: []
  }),
  computed: {
    collections () {
      if (this.$route.query.ref) {
        return this.metrics.filter(metric => ((metric.category === this.$route.query.ref) && metric.active === true))
      }

      return this.metrics.filter(metric => metric.active === true)
    },
    getCollections () {
      return (this.search === '' ? this.collections : this.getSearch())
    },
    getDashboards () {
      return (this.dashboardQuery === '' ? this.dashboardsList : this.getSearchDashboard())
    },
    getPeriods () {
      const result = this.activeMetric.category === 'MRR' ? ['monthly'] : ['monthly', 'daily', 'yearly']
      return result
    },
    getComparatives () {
      const result = this.activeMetric.category === 'MRR' ? ['this_v_last', 'last_v_one_before'] : ['this_v_last', 'this_v_last_mtd', 'last_v_one_before']
      return result
    },
    ...mapState({
      dashboardsList: state => state.dashboards.dashboardsList,
      dashboardsDetail: state => state.dashboards.dashboardsDetail
    })
  },
  watch: {
    search: debounce(function () {
      if (this.search.length < 3) {
        return false
      }

      this.getSearch()
    }, 600)
  },
  created () {
    if (this.$route.query.dash !== undefined && !this.dialogDashboard) {
      this.getDashboardDetails(this.$route.query.dash)
    } else {
      this.$store.dispatch('dashboards/LIST', {
        resource: 'metrics/dashboards'
      })
    }
  },
  methods: {
    getDashboardDetails (id) {
      this.$store.dispatch('dashboards/RETRIEVE', {
        resource: 'metrics/dashboards',
        id: id
      })
      .then((response) => {
        this.draftDashboards = cloneDeep(response.data)
        this.dashboardSelected = this.draftDashboards
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    getSearch () {
      if (this.search !== '') {
        if (!this.$route.query.ref) {
          return this.metrics.filter(metric => this.$t(`metrics.${metric.name}`).toUpperCase().includes(this.search.toUpperCase()) && metric.active === true)
        } else {
          return this.metrics.filter(metric => ((metric.category === this.$route.query.ref) && this.$t(`metrics.${metric.name}`).toUpperCase().includes(this.search.toUpperCase()) && metric.active === true))
        }
      }
    },
    getSearchDashboard () {
      if (this.dashboardQuery !== '') {
        return this.dashboardsList.filter(dashboard => dashboard.name.toUpperCase().includes(this.dashboardQuery.toUpperCase()))
      }
    },
    setActiveMetric (metric) {
      this.activeMetric = metric
      this.dialogMetric = true

      // reset interval & comparative
      this.activeGraphInterval = 'monthly'
      this.activeKpiComparative = 'this_v_last'
      this.activeKpiInterval = 'monthly'
    },
    setDashboard (id) {
      this.$route.query.dash = undefined
      this.getDashboardDetails(id)
      this.dashboardQuery = ''
      this.dialogDashboard = false
    },
    addMetrics (item) {
      if (this.type === 'kpi') {
        this.metricsAdded.push({ id: (this.metricsAdded.length + 1), name: this.activeMetric.name, type: { name: this.type, config: item }, interval: this.activeKpiInterval, comparative: this.activeKpiComparative })
      }

      if (this.type === 'graph') {
        this.metricsAdded.push({ id: (this.metricsAdded.length + 1), name: this.activeMetric.name, type: { name: this.type, config: item }, interval: this.activeGraphInterval })
      }

      if (this.type === 'table') {
        this.metricsAdded.push({ id: (this.metricsAdded.length + 1), name: this.activeMetric.name, type: { name: this.type, config: item } })
      }

      this.dialogMetric = false
    },
    goToDashboard (id) {
      this.draftDashboards.layout = this.metricsAdded

      this.$store.dispatch('dashboards/DASHBOARD_LIST', this.draftDashboards)
       this.$router.push({
        name: 'DashboardUpdate',
        params: {
          id: id
        }
      })
    },
    deleteChip (id) {
      let index = this.metricsAdded.findIndex(m => m.id === id)
      this.metricsAdded.splice(index, 1)
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.metricsAdded.length) {
      if (to.name !== 'DashboardUpdate') {
        const dialog = await this.$dialog.warning({
          title: 'Cambios sin guardar',
          text: `
            <span class="d-block my-2">Hay cambios que no han sido guardados</span>
            <span class="d-block my-2">¿Estás seguro de continuar?</span>
          `,
          actions: [
            {
              color: 'red',
              text: 'Continuar',
              class: 'mx-12'
            },
            {
              color: '',
              text: 'Cancel'
            }
          ]
        })
        dialog === 'Continuar' ? next(true) : next(false)
      } else {
        next(true)
      }
    } else {
      next(true)
    }
  }
}
</script>
<style scoped>
.box-hidden {
  width: 70px;
  height: 35px;
  position: absolute;
  right: 15px;
}
</style>